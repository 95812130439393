import { FEATURE } from 'api/src/common/enums'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import OnboardingMembershipGroups from 'src/components/Onboarding/OnboardingMembershipGroups'
import OnboardingPageHeader from 'src/components/Onboarding/OnboardingPageHeader'
import useFeature from 'src/lib/hooks/useFeature'

const OnboardingMembershipGroupsPage = () => {
  // Get Feature Flag Status
  const onboardingFeatureFlag = useFeature(FEATURE.ONBOARDING)

  // Navigate away when permission denied
  if (!onboardingFeatureFlag) {
    navigate(routes.home())
  }

  return (
    <>
      <Metadata
        title="Setup - Membership Groups"
        description="Setup - Membership Groups page"
      />
      <div className="relative">
        <OnboardingPageHeader />
        <OnboardingMembershipGroups />
      </div>
    </>
  )
}

export default OnboardingMembershipGroupsPage
