import { FC } from 'react'

import { Divider } from '@mui/material'

import { routes } from '@redwoodjs/router'

import GroupsDataGridCell from '../GroupsDataGridCell'

import OnboardingSubNavigation, {
  ButtonOption,
} from './OnboardingSubNavigation'

const OnboardingMembershipGroups: FC = () => {
  const backButton: ButtonOption = {
    label: 'Members',
    url: routes.onboardingMembers(),
  }

  const nextButton: ButtonOption = {
    label: 'Home Links',
    url: routes.onboardingHomeLinks(),
  }

  return (
    <div className="max-w-6xl py-24 p-4 mx-auto">
      <div className="flex flex-col gap-8">
        <OnboardingSubNavigation
          title="Groups"
          backButton={backButton}
          nextButton={nextButton}
        />
        <div className="bg-white px-2 border rounded-xl pt-4 ">
          <div className="px-10 mt-14 w-full">
            <div className="flex items-center justify-between gap-4 flex-wrap">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Membership Groups
                </h3>
                <p className="max-w-2xl text-sm text-gray-500">
                  Create Groups to control what content members can access.
                </p>
              </div>
            </div>
            <Divider className="mt-6" />
          </div>
          <div className="min-h-[400px] flex items-center">
            <div className="w-full">
              <GroupsDataGridCell />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnboardingMembershipGroups
